import { toRaw, ref, watch } from 'vue'

export default function useForms(formData: any) {
  const formRef = ref()
  const formState = ref(formData?.value?.resetData)

  watch(
    formData?.value?.dataState,
    (newVal) => {
      formState.value = toRaw(newVal)
    },
    { deep: true, immediate: true }
  )

  return {
    formRef,
    formState
  }
}
